import React, { Component } from 'react';
import { translate } from '../../base/i18n/functions';
/**
 * A component that renders the description of the notification for old EDCZOOM Meet Electron clients.
 *
 * @augments AbstractApp
 */
export class OldElectronAPPNotificationDescription extends Component {
    /**
     * Implements React's {@link Component#render()}.
     *
     * @inheritdoc
     * @returns {ReactElement}
     */
    render() {
        const { t } = this.props;
        return (React.createElement("div", null,
            t('notify.oldElectronClientDescription1'),
            React.createElement("a", { href: 'https://github.com/jitsi/jitsi-meet-electron/releases/latest', rel: 'noopener noreferrer', target: '_blank' }, t('notify.oldElectronClientDescription2')),
            t('notify.oldElectronClientDescription3')));
    }
}
export default translate(OldElectronAPPNotificationDescription);
